





















import {Component, Vue} from "vue-property-decorator";
import {BButton, BCard, BContainer, BNav, BNavItem, BOverlay, BSpinner} from "bootstrap-vue";
import {VueGoodTable} from 'vue-good-table'

@Component({
  components: {
    VueGoodTable,
    BContainer,
    BCard,
    BOverlay,
    BNav,
    BNavItem,
    BSpinner,
    BButton
  }
})
export default class Surveys extends Vue {
  columns = [
    {
      label: 'Name',
      field: 'name',
    },
  ]

  items: RegistrationsAPI.Surveys.Item[] = [];

  async mounted() {
    await this.getSurveys();
  }

  async getSurveys() {
    this.items = await this.$api.registrations.surveys.GetList();
  }

  async goTo(params) {
    await this.$router.push({
      name: "registration-survey",
      params: {
        identifier: params.row.uniqueId
      }
    })
  }

}
